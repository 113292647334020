import TextareaAutogrow from "stimulus-textarea-autogrow";

export default class extends TextareaAutogrow {
  connect() {
    super.connect();

    this.element.addEventListener("focus", this.autogrow);
    this.element.addEventListener("blur", this.autogrow);
  }

  autogrow() {
    this.element.style.height = "auto"; // Force re-print before calculating the scrollHeight value.
    this.element.style.height = `${Math.max(this.element.scrollHeight, 150)}px`;
  }
}
