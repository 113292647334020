import Timeago from '@stimulus-components/timeago'
import { de } from 'date-fns/locale'

export default class extends Timeago {
  connect() {
    super.connect()
    console.log('Do what you want here.')
  }

  // You can override this getter to change the locale.
  // Don't forget to import it.
  get locale() {
    return de
  }
}
