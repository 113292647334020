// Entry point for the build script in your package.json
// import "@hotwired/turbo-rails";

// Change according to TurboPower docs
import * as Turbo from '@hotwired/turbo'

import "./controllers";

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

window.Turbo = Turbo;

import "bootstrap";

import "rhino-editor"
// import "rhino-editor/exports/styles/trix.css"

// import Uppy from '@uppy/core';
// import Dashboard from '@uppy/dashboard';

// new Uppy().use(Dashboard, {
//   inline: true,
//   width: '100%',
//   height: 'fit-content',
//   theme: 'dark',
//   target: '#uppy-dashboard',
//   proudlyDisplayPoweredByUppy: false,

//   metaFields: [
// 		{ id: 'name', name: 'Name', placeholder: 'file name' },
// 		{ id: 'license', name: 'License', placeholder: 'specify license' },
// 		{
// 			id: 'caption',
// 			name: 'Caption',
// 			placeholder: 'describe what the image is about',
// 		},
// 		{
// 			id: 'public',
// 			name: 'Public',
// 			render({ value, onChange, required, form }, h) {
// 				return h('input', {
// 					type: 'checkbox',
// 					required,
// 					form,
// 					onChange: (ev) => onChange(ev.target.checked ? 'on' : ''),
// 					defaultChecked: value === 'on',
// 				});
// 			},
// 		},
// 	],

// });
