import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";


export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {

  }

  disconnect() {
  }

  switch = (event) => {
    let view = event.target.closest("a").dataset.view;

    patch(this.urlValue, {
      headers: {
        Accept:
          "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
      },
      body: JSON.stringify({
        settings: { calendar: { view: view } }
      }),
    });
  }
}
