import { Controller } from "@hotwired/stimulus"
import Quill from 'quill';

export default class extends Controller {
  connect() {


    // import("trix/dist/trix.css").then(trix_css => {
    //   console.log('loading trix.css')
    // });

    // const quill = new Quill(this.element);

    const quill = new Quill(this.element, {
      modules: {
        toolbar: [
          ['bold', 'italic', 'link'],
          ['blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }]
        ],
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: false
        }
      },
      placeholder: 'Compose an epic...',
      theme: 'snow', // or 'bubble'
    });

  }
}


// { list:['bullet', 'ordered'] }
