import { Controller } from "@hotwired/stimulus";
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  connect() {
    const sat = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat").replace('px', ''));

    useIntersection(this, {
      threshold: 1.0,
      rootMargin: `-${sat + 1}px 0px 0px 0px`,
      visibleAttribute: "visible"
    });
  }

  appear() {
    this.element.classList.remove("sticked");
  }

  disappear() {
    this.element.classList.add("sticked");
  }
}
